import React from 'react';

import { CONFIG } from '@/config';

export const LogoFooter = ({ color = '#02004e' }) => {
  return (
    <svg
      width="522"
      height="402"
      viewBox="0 0 522 402"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      alt={CONFIG.title}
    >
      <mask
        id="mask0_389_1109"
        masktype="luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="522"
        height="402"
      >
        <path d="M0 402H522V-7.62939e-06H0V402Z" fill="white" />
      </mask>
      <g mask="url(#mask0_389_1109)">
        <path
          d="M364.869 87.4495L363.159 86.8879L315.369 221.652C318.265 222.104 321.099 222.657 323.823 223.427L364.869 87.4495Z"
          fill="#B2A273"
        />
        <path
          d="M286.134 11.9602L284.342 12.1505L303.258 220.65C306.336 220.668 309.323 220.895 312.246 221.229L286.134 11.9602Z"
          fill="#B2A273"
        />
        <path
          d="M396.427 151.979L395.069 150.801L327.132 224.555C329.802 225.47 332.391 226.52 334.862 227.752L396.427 151.979Z"
          fill="#B2A273"
        />
        <path
          d="M404.054 204.986L403.266 203.365L340.479 230.935C342.715 232.376 344.878 233.915 346.896 235.645L404.054 204.986Z"
          fill="#B2A273"
        />
        <path
          d="M169.991 0.00213469L168.425 0.889672L289.962 221.707C293.076 221.191 296.307 220.901 299.62 220.774L169.991 0.00213469Z"
          fill="#B2A273"
        />
        <path
          d="M112.152 93.1813L111.048 94.6034L274.237 226.251C277.315 224.929 280.546 223.842 283.913 222.981L112.152 93.1813Z"
          fill="#B2A273"
        />
        <path
          d="M132.967 196.963L132.47 198.693L256.93 237.604C259.138 235.52 261.456 233.564 263.954 231.852L132.967 196.963Z"
          fill="#B2A273"
        />
        <path
          d="M40.1956 251.89C51.7444 279.27 69.4396 322.229 75.7027 334.846C73.5666 334.501 71.5662 334.257 69.4396 334.257C67.3218 334.257 65.3119 334.501 63.1853 334.846C60.9495 326.712 55.2927 313.724 51.0571 303.227C45.9703 303.109 40.7837 302.982 35.5978 302.982C30.7646 302.982 26.04 303.109 21.2068 303.227C16.2468 315.381 11.7751 327.419 9.7753 334.846C8.23621 334.501 6.81512 334.257 5.39466 334.257C3.99178 334.257 2.57069 334.501 1.04102 334.846C13.6582 307.23 25.9314 279.732 37.951 251.89H40.1956ZM36.3039 267.822L23.4514 297.801C27.5784 297.801 32.0589 298.028 36.1859 298.028C40.5396 298.028 45.0288 297.919 49.4006 297.801L36.3039 267.822Z"
          fill={color}
        />
        <path
          d="M0 335.697L0.497757 334.601C13.8211 305.446 25.8953 278.31 37.4077 251.654L37.5615 251.301H40.5939L40.7388 251.654C43.3996 257.949 46.3862 265.077 49.4731 272.459C59.7641 297.032 71.4215 324.883 76.2365 334.583L76.7525 335.625L75.612 335.435C70.625 334.619 68.2354 334.619 63.2848 335.435L62.76 335.525L62.615 335.009C60.8864 328.723 57.1391 319.602 53.5098 310.79C52.5231 308.398 51.564 306.053 50.6494 303.807H50.5954C45.6988 303.698 40.6303 303.58 35.5975 303.58C31.0812 303.58 26.7458 303.68 22.1662 303.797L21.6138 303.807C16.1561 317.202 12.1558 328.27 10.3543 335.009L10.2006 335.562L9.6482 335.425C6.1005 334.628 4.70641 334.628 1.17692 335.425L0 335.697ZM35.5975 302.393C40.639 302.393 45.7164 302.512 50.6224 302.62L51.4642 302.638L51.6092 303C52.5595 305.355 53.5732 307.819 54.6045 310.337C58.1528 318.95 61.8097 327.844 63.6193 334.176C67.9548 333.505 70.4712 333.478 74.671 334.085C69.5661 323.533 58.3248 296.696 48.3778 272.921C45.3455 265.675 42.4129 258.692 39.7973 252.479H38.3398C27.0176 278.699 15.1424 305.391 2.07263 334.021C3.08634 333.822 4.24506 333.668 5.39435 333.668C6.56185 333.668 7.76576 333.814 9.34942 334.148C11.2595 327.265 15.2597 316.242 20.6547 303L20.8085 302.638L22.1298 302.611C26.7276 302.493 31.0725 302.393 35.5975 302.393ZM36.1862 298.626C34.1224 298.626 31.9594 298.563 29.8051 298.508C27.6597 298.454 25.5055 298.39 23.4511 298.39H22.5553L36.2948 266.318L50.2967 298.372L49.4185 298.39C45.3185 298.499 40.666 298.626 36.1862 298.626ZM24.3562 297.204C26.1394 297.221 27.9855 297.267 29.8409 297.321C31.9769 297.385 34.1312 297.439 36.1862 297.439C40.3315 297.439 44.6305 297.331 48.4952 297.231L36.3124 269.316L24.3562 297.204Z"
          fill={color}
        />
        <path
          d="M96.803 251.89C107.538 274.071 118.625 294.849 130.663 316.912C135.369 307.71 154.114 273.727 165.681 251.89H167.211C170.759 282.105 173.112 307.828 176.877 334.846C174.995 334.501 173.23 334.257 171.338 334.257C169.447 334.257 167.69 334.501 165.799 334.846C164.161 313.724 162.496 294.133 160.024 273.953H159.789C149.054 293.662 138.202 313.027 128.88 334.257H127.821C118.625 314.675 107.773 294.377 97.5273 273.953H97.2825C95.0379 293.418 91.7256 321.523 91.6076 334.846C90.2047 334.501 88.9017 334.257 87.4806 334.257C86.0683 334.257 84.774 334.501 83.3623 334.846C87.4806 308.77 92.2052 276.897 94.8026 251.89H96.803Z"
          fill={color}
        />
        <path
          d="M82.6338 335.639L82.7694 334.752C88.2271 300.279 92.0742 272.374 94.2102 251.832L94.2736 251.298H97.1786L97.3418 251.632C108.321 274.33 119.824 295.796 130.641 315.65C133.256 310.623 138.723 300.56 144.977 289.039C151.711 276.631 159.35 262.574 165.161 251.614L165.324 251.298H167.741L167.804 251.823C169.062 262.537 170.175 272.7 171.243 282.527C173.199 300.425 175.036 317.334 177.471 334.77L177.588 335.585L176.773 335.44C174.592 335.032 172.963 334.851 171.343 334.851C169.316 334.851 167.451 335.15 165.903 335.44L165.261 335.549L165.206 334.897C163.505 312.932 161.884 294.356 159.613 275.526L156.916 280.453C147.493 297.698 137.754 315.523 129.427 334.498L129.274 334.851H127.446L127.283 334.516C121.227 321.618 114.303 308.114 107.596 295.053C104.329 288.686 100.971 282.12 97.6858 275.607L97.5502 276.794C95.3413 295.95 92.3096 322.171 92.201 334.851L92.1916 335.612L91.4679 335.422C90.3274 335.141 88.9697 334.851 87.4852 334.851C86.0189 334.851 84.7064 335.132 83.5025 335.422L82.6338 335.639ZM159.431 273.361H160.554L160.617 273.886C162.97 293.07 164.609 311.882 166.338 334.145C167.804 333.891 169.496 333.665 171.343 333.665C172.827 333.665 174.311 333.801 176.176 334.118C173.805 316.954 171.985 300.288 170.058 282.654C169.007 272.99 167.922 263.008 166.682 252.484H166.039C160.246 263.416 152.698 277.319 146.027 289.601C139.32 301.946 133.527 312.615 131.192 317.189L130.676 318.185L130.143 317.199C119.209 297.164 107.561 275.453 96.4272 252.484H95.3413C93.2053 272.827 89.4222 300.27 84.0819 334.072C85.1226 333.846 86.2449 333.665 87.4852 333.665C88.7883 333.665 89.974 333.864 91.0241 334.1C91.2413 321.102 94.2008 295.47 96.3645 276.658L96.7536 273.361H97.8935L98.0567 273.687C101.55 280.652 105.162 287.699 108.665 294.51C115.299 307.462 122.169 320.857 128.206 333.665H128.495C136.804 314.771 146.507 297.037 155.884 279.873L159.431 273.361Z"
          fill={color}
        />
        <path
          d="M223.705 251.89C235.254 279.27 252.949 322.229 259.204 334.846C257.076 334.501 255.067 334.257 252.949 334.257C250.822 334.257 248.822 334.501 246.695 334.846C244.459 326.712 238.803 313.724 234.557 303.227C229.489 303.109 224.293 302.982 219.108 302.982C214.274 302.982 209.55 303.109 204.717 303.227C199.757 315.381 195.285 327.419 193.276 334.846C191.737 334.501 190.325 334.257 188.913 334.257C187.492 334.257 186.071 334.501 184.551 334.846C197.168 307.23 209.441 279.732 221.461 251.89H223.705ZM219.814 267.822L206.961 297.801C211.088 297.801 215.559 298.028 219.696 298.028C224.049 298.028 228.539 297.919 232.892 297.801L219.814 267.822Z"
          fill={color}
        />
        <path
          d="M183.51 335.697L184.008 334.601C197.331 305.446 209.405 278.31 220.917 251.654L221.071 251.301H224.104L224.249 251.654C226.928 257.994 229.932 265.168 233.046 272.613C243.31 297.122 254.941 324.901 259.737 334.583L260.244 335.625L259.104 335.435C254.144 334.619 251.745 334.619 246.795 335.435L246.27 335.525L246.125 335.009C244.378 328.678 240.604 319.494 236.938 310.609C235.978 308.29 235.037 305.998 234.15 303.807C229.236 303.698 224.158 303.58 219.107 303.58C214.591 303.58 210.256 303.68 205.676 303.797L205.124 303.807C199.675 317.184 195.674 328.252 193.855 335.009L193.701 335.562L193.14 335.425C189.583 334.619 188.144 334.638 184.687 335.425L183.51 335.697ZM219.107 302.393C224.185 302.393 229.281 302.512 234.223 302.62L234.965 302.638L235.11 303C236.033 305.292 237.028 307.701 238.042 310.156C241.617 318.842 245.31 327.809 247.129 334.176C251.456 333.496 253.954 333.478 258.171 334.085C253.085 323.551 241.88 296.778 231.951 273.066C228.892 265.775 225.95 258.728 223.307 252.479H221.85C210.527 278.699 198.652 305.391 185.582 334.021C186.858 333.777 187.917 333.668 188.922 333.668C190.316 333.668 191.71 333.904 192.85 334.148C194.769 327.247 198.779 316.224 204.164 303L204.318 302.638L205.648 302.611C210.237 302.493 214.582 302.393 219.107 302.393ZM219.696 298.626C217.623 298.626 215.46 298.563 213.306 298.508C211.161 298.454 209.015 298.39 206.961 298.39H206.065L219.805 266.318L233.797 298.372L232.91 298.39C228.828 298.499 224.185 298.626 219.696 298.626ZM207.866 297.204C209.649 297.221 211.495 297.267 213.333 297.321C215.487 297.385 217.632 297.439 219.696 297.439C223.859 297.439 228.14 297.331 231.996 297.231L219.822 269.316L207.866 297.204Z"
          fill={color}
        />
        <path
          d="M330.216 329.663C327.275 332.028 317.49 336.393 305.697 336.393C280.797 336.393 262.65 320.923 262.65 294.14C262.65 267.34 280.797 251.888 306.874 251.888C317.019 251.888 326.107 254.361 332.461 257.305C331.293 259.433 330.46 264.043 329.999 266.525L329.284 266.751C325.971 263.092 317.961 256.254 306.874 256.254C288.364 256.254 274.199 267.929 274.199 294.14C274.199 320.334 288.364 332.028 306.874 332.028C318.088 332.028 326.333 325.642 330.804 322.227L331.293 322.698L330.216 329.663Z"
          fill={color}
        />
        <path
          d="M305.7 336.988C279.19 336.988 262.057 320.168 262.057 294.138C262.057 268.107 279.651 251.296 306.877 251.296C319.087 251.296 328.301 254.729 332.709 256.767L333.288 257.039L332.98 257.592C331.903 259.566 331.08 263.995 330.627 266.377L330.509 266.984L329.106 267.437L328.844 267.156C326.663 264.738 318.616 256.84 306.877 256.84C286.485 256.84 274.791 270.435 274.791 294.138C274.791 317.841 286.485 331.426 306.877 331.426C317.793 331.426 325.957 325.195 330.328 321.835L330.736 321.527L331.224 321.798L331.93 322.487L330.772 329.987L330.591 330.131C327.803 332.369 318.046 336.988 305.7 336.988ZM306.877 252.483C279.968 252.483 263.242 268.451 263.242 294.138C263.242 319.453 279.914 335.801 305.7 335.801C317.358 335.801 326.843 331.463 329.667 329.334L330.627 323.112C325.92 326.698 317.765 332.613 306.877 332.613C286.042 332.613 273.605 318.23 273.605 294.138C273.605 270.036 286.042 255.653 306.877 255.653C318.67 255.653 326.862 263.279 329.468 266.069L329.477 266.06C329.93 263.66 330.645 259.847 331.658 257.6C324.4 254.34 315.412 252.483 306.877 252.483Z"
          fill={color}
        />
        <path
          d="M347.911 283.991C347.911 273.838 347.45 263.567 346.97 253.423C348.97 253.776 350.862 254.003 352.744 254.003C354.627 254.003 356.537 253.776 358.519 253.423C358.057 263.567 357.587 273.838 357.587 283.991C357.587 303.818 357.813 317.748 357.949 329.305C369.027 329.305 378.694 329.305 390.478 327.784C390.134 328.952 389.899 330.138 389.899 331.307C389.899 332.485 390.134 333.671 390.478 334.848C383.301 334.495 375.978 334.26 368.783 334.26C361.47 334.26 354.274 334.495 346.97 334.848C347.45 324.695 347.911 314.433 347.911 304.28V283.991Z"
          fill={color}
        />
        <path
          d="M391.291 335.482L390.458 335.446C382.331 335.048 375.244 334.857 368.781 334.857C362.301 334.857 355.386 335.038 347.005 335.446L346.344 335.474L346.38 334.821C346.905 323.463 347.321 314.007 347.321 304.28V283.992C347.321 274.255 346.905 264.799 346.38 253.451L346.344 252.707L347.077 252.834C349.286 253.224 351.087 253.405 352.743 253.405C354.417 253.405 356.209 253.224 358.418 252.834L359.151 252.707L359.115 253.451C358.662 263.423 358.183 273.73 358.183 283.992C358.183 300.883 358.346 313.708 358.481 324.016L358.535 328.717C369.397 328.717 378.855 328.689 390.404 327.186L391.318 327.068L391.056 327.947C390.685 329.206 390.495 330.31 390.495 331.307C390.495 332.312 390.685 333.417 391.056 334.677L391.291 335.482ZM347.602 254.138C348.009 262.933 348.507 273.739 348.507 283.992V304.28C348.507 313.845 348.118 323.137 347.602 334.224C355.711 333.843 362.455 333.671 368.781 333.671C375.045 333.671 381.887 333.843 389.689 334.214C389.436 333.164 389.309 332.213 389.309 331.307C389.309 330.437 389.427 329.505 389.68 328.481C378.249 329.903 368.818 329.903 357.947 329.903H357.368L357.287 324.025C357.16 313.727 356.997 300.893 356.997 283.992C356.997 273.956 357.449 263.893 357.893 254.138C354.01 254.755 351.521 254.763 347.602 254.138Z"
          fill={color}
        />
        <path
          d="M406.878 283.991C406.878 273.838 406.417 263.567 405.937 253.423C407.937 253.776 409.829 254.003 411.711 254.003C413.603 254.003 415.504 253.776 417.495 253.423C417.024 263.567 416.554 273.838 416.554 283.991V304.28C416.554 314.433 417.024 324.695 417.495 334.848C415.504 334.495 413.603 334.26 411.711 334.26C409.829 334.26 407.937 334.495 405.937 334.848C406.417 324.695 406.878 314.433 406.878 304.28V283.991Z"
          fill={color}
        />
        <path
          d="M418.125 335.564L417.392 335.437C412.83 334.631 410.631 334.631 406.042 335.437L405.309 335.564L405.345 334.822C405.87 323.463 406.286 314.007 406.286 304.28V283.992C406.286 273.485 405.761 262.381 405.345 253.45L405.309 252.707L406.042 252.834C408.25 253.224 410.051 253.405 411.707 253.405C413.373 253.405 415.183 253.224 417.392 252.834L418.125 252.707L418.088 253.45C417.672 262.381 417.147 273.485 417.147 283.992V304.28C417.147 314.007 417.564 323.463 418.088 334.822L418.125 335.564ZM411.707 333.671C413.237 333.671 414.858 333.816 416.867 334.142C416.351 323.092 415.962 313.818 415.962 304.28V283.992C415.962 273.739 416.459 262.933 416.867 254.139C412.956 254.754 410.486 254.764 406.566 254.139C406.974 262.933 407.472 273.739 407.472 283.992V304.28C407.472 313.818 407.082 323.092 406.566 334.142C408.576 333.816 410.187 333.671 411.707 333.671Z"
          fill={color}
        />
        <path
          d="M477.172 251.89C503.23 251.89 521.405 267.342 521.405 294.133C521.405 320.924 503.23 336.395 477.172 336.395C451.223 336.395 433.066 320.924 433.066 294.133C433.066 267.342 451.223 251.89 477.172 251.89ZM477.172 256.246C458.78 256.246 444.616 267.94 444.616 294.133C444.616 320.336 458.78 332.02 477.172 332.02C495.691 332.02 509.855 320.336 509.855 294.133C509.855 267.94 495.691 256.246 477.172 256.246Z"
          fill={color}
        />
        <path
          d="M477.171 336.988C450.018 336.988 432.478 320.168 432.478 294.138C432.478 268.107 450.018 251.296 477.171 251.296C504.406 251.296 522.001 268.107 522.001 294.138C522.001 320.168 504.406 336.988 477.171 336.988ZM477.171 252.483C450.335 252.483 433.663 268.451 433.663 294.138C433.663 319.453 450.743 335.801 477.171 335.801C504.089 335.801 520.815 319.842 520.815 294.138C520.815 268.451 504.089 252.483 477.171 252.483ZM477.171 332.613C456.418 332.613 444.026 318.23 444.026 294.138C444.026 270.036 456.418 255.653 477.171 255.653C498.007 255.653 510.452 270.036 510.452 294.138C510.452 318.23 498.007 332.613 477.171 332.613ZM477.171 256.84C456.861 256.84 445.212 270.435 445.212 294.138C445.212 317.841 456.861 331.426 477.171 331.426C497.563 331.426 509.266 317.841 509.266 294.138C509.266 270.435 497.563 256.84 477.171 256.84Z"
          fill={color}
        />
        <path
          d="M19.049 388.815H13.1387V401.387H7.3916V368.445H16.6688C21.7732 368.445 23.6381 368.926 25.5569 370.013C28.6338 371.734 30.3537 374.686 30.3537 378.3C30.3537 384.767 25.3573 388.815 19.049 388.815ZM13.1387 372.874V384.441H17.6826C21.9728 384.441 23.9368 382.539 23.9368 378.626C23.9368 376.823 23.4843 375.447 22.5245 374.487C21.4744 373.445 19.9542 372.874 17.6826 372.874H13.1387Z"
          fill="#B2A273"
        />
        <path
          d="M72.3452 401.384L69.3222 396.294C66.8962 392.246 65.2762 389.963 63.3573 388.152C62.7058 387.535 62.1992 387.201 61.0945 387.155V401.384H55.3838V368.443H66.0369C73.8567 368.443 77.3956 372.727 77.3956 377.872C77.3956 382.971 73.9565 386.91 68.7065 386.91C69.965 387.535 72.2906 390.724 74.1102 393.486L79.2604 401.384H72.3452ZM61.0945 372.872V383.297H64.0635C67.0958 383.297 68.7065 382.916 69.7748 381.92C70.7339 381.014 71.3315 379.629 71.3315 377.917C71.3315 374.583 69.4126 372.872 64.2719 372.872H61.0945Z"
          fill="#B2A273"
        />
        <path
          d="M102.427 384.914C102.427 374.063 108.274 367.968 117.008 367.968C126.846 367.968 131.598 375.35 131.598 384.969C131.598 395.249 126.349 401.906 117.262 401.906C107.315 401.906 102.427 394.678 102.427 384.914ZM124.982 385.295C124.982 377.252 123.217 372.207 116.963 372.207C111.106 372.207 108.889 376.672 108.889 384.579C108.889 393.057 111.161 397.766 117.117 397.766C122.312 397.766 124.982 394.153 124.982 385.295Z"
          fill="#B2A273"
        />
        <path
          d="M179.03 396.768C176.107 400.291 173.075 401.387 166.567 401.387H157.933V368.446H165.201C171.762 368.446 175.645 369.587 178.931 373.11C181.502 375.918 182.714 379.867 182.714 385.011C182.714 390.011 181.411 393.906 179.03 396.768ZM174.034 375.628C172.423 373.536 170.604 372.784 167.472 372.784H163.788V396.904H167.626C173.781 396.904 176.252 393.055 176.252 385.582C176.252 381.343 175.854 377.965 174.034 375.628Z"
          fill="#B2A273"
        />
        <path
          d="M221.574 401.954C212.387 401.954 209.509 397.48 209.509 392.199V368.442H215.364V390.587C215.364 395.632 217.283 397.29 221.574 397.29C225.819 397.29 227.882 394.953 227.882 390.587V368.442H233.684V392.154C233.684 398.195 229.249 401.954 221.574 401.954Z"
          fill="#B2A273"
        />
        <path
          d="M274.857 401.907C270.214 401.907 266.53 400.24 263.806 397.152C261.181 394.154 259.823 390.15 259.823 385.386C259.823 381.446 260.575 378.149 262.14 375.396C264.765 370.686 269.562 367.824 275.011 367.824C278.396 367.824 281.627 368.784 283.69 370.396L281.011 374.01C279.147 372.733 277.237 372.163 275.056 372.163C272.088 372.163 269.608 373.585 268.096 376.103C266.883 378.104 266.286 380.866 266.286 384.77C266.286 388.819 266.738 391.101 267.644 392.958C269.155 396.101 272.033 397.623 275.418 397.623C277.889 397.623 279.699 397.007 281.672 395.531L284.397 398.999C281.672 400.956 278.595 401.907 274.857 401.907Z"
          fill="#B2A273"
        />
      </g>
      <path
        d="M305.904 368.448V373.013H314.738V401.381H320.531V373.013H329.328L330.07 368.448H305.904Z"
        fill="#B2A273"
      />
      <path
        d="M354.71 401.384H363.381V368.452H354.71V401.384Z"
        fill="#B2A273"
      />
      <mask
        id="mask1_389_1109"
        masktype="luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="522"
        height="402"
      >
        <path d="M0 402H522V-7.43866e-06H0V402Z" fill="white" />
      </mask>
      <g mask="url(#mask1_389_1109)">
        <path
          d="M386.969 384.914C386.969 374.063 392.816 367.968 401.55 367.968C411.388 367.968 416.14 375.35 416.14 384.969C416.14 395.249 410.891 401.906 401.804 401.906C391.857 401.906 386.969 394.678 386.969 384.914ZM409.524 385.295C409.524 377.252 407.759 372.207 401.505 372.207C395.648 372.207 393.431 376.672 393.431 384.579C393.431 393.057 395.703 397.766 401.659 397.766C406.845 397.766 409.524 394.153 409.524 385.295Z"
          fill="#B2A273"
        />
        <path
          d="M459.901 401.384L452.08 385.914C450.207 382.246 448.297 378.107 447.636 376.151L447.483 376.296C447.736 379.058 447.791 382.536 447.836 385.434L448.035 401.384H442.342V368.443H448.895L457.376 384.538C458.996 387.581 460.507 391.484 460.96 393.006L461.113 392.861C460.96 391.195 460.607 386.494 460.607 383.487L460.507 368.443H466.01V401.384H459.901Z"
          fill="#B2A273"
        />
        <path
          d="M503.31 402C499.327 402 495.291 401.004 491.852 399.057L494.069 394.863C497.201 396.575 499.979 397.58 503.509 397.58C507.809 397.58 510.379 395.633 510.379 392.336C510.379 389.917 508.768 388.387 505.184 387.436L501.446 386.439C498.268 385.579 495.997 384.347 494.684 382.49C493.725 381.15 493.218 379.583 493.218 377.672C493.218 371.875 498.061 367.917 505.075 367.917C509.067 367.917 513.049 369.013 516.136 371.06L513.501 374.918C510.279 373.107 508.053 372.401 505.283 372.401C501.844 372.401 499.572 374.158 499.572 376.875C499.572 378.922 500.785 379.963 504.224 380.924L508.36 382.056C513.411 383.441 516.941 386.494 516.941 391.439C516.941 396.91 512.243 402 503.31 402Z"
          fill="#B2A273"
        />
      </g>
    </svg>
  );
};

export const Logo = ({ color = '#221F20' }) => {
  return (
    <svg
      width="829"
      height="239"
      viewBox="0 0 829 239"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      alt={CONFIG.title}
    >
      <g clipPath="url(#clip0_662_3531)">
        <path
          d="M56.3802 25.3199L35.9002 73.0499C42.4802 73.0499 49.6102 73.4099 56.1902 73.4099C63.1302 73.4099 70.2802 73.2399 77.2402 73.0499L56.3802 25.3199ZM62.5802 -0.0400391C80.9802 43.55 109.17 111.94 119.15 132.03C115.75 131.48 112.56 131.09 109.17 131.09C105.8 131.09 102.59 131.48 99.2102 132.03C95.6502 119.08 86.6402 98.3999 79.8902 81.6899C71.7902 81.4999 63.5202 81.2999 55.2602 81.2999C47.5602 81.2999 40.0302 81.4999 32.3302 81.6899C24.4302 101.04 17.3002 120.2 14.1202 132.03C11.6702 131.48 9.4002 131.09 7.1402 131.09C4.9002 131.09 2.6402 131.48 0.200195 132.03C20.3002 88.06 39.8502 44.29 59.0002 -0.0400391H62.5802Z"
          fill={color}
        />
        <path
          d="M152.76 -0.0400391C169.86 35.27 187.53 68.3499 206.7 103.48C214.2 88.8299 244.06 34.73 262.49 -0.0400391H264.93C270.58 48.06 274.33 89.02 280.33 132.03C277.33 131.48 274.52 131.09 271.51 131.09C268.5 131.09 265.7 131.48 262.69 132.03C260.08 98.4 257.43 67.21 253.49 35.09H253.12C236.02 66.47 218.73 97.2999 203.88 131.1H202.19C187.54 99.9199 170.25 67.61 153.93 35.09H153.54C149.96 66.08 144.69 110.82 144.5 132.03C142.26 131.48 140.19 131.09 137.92 131.09C135.67 131.09 133.61 131.48 131.36 132.03C137.92 90.52 145.45 39.77 149.59 -0.0400391H152.76Z"
          fill={color}
        />
        <path
          d="M348.74 25.3199L328.26 73.0499C334.84 73.0499 341.96 73.4099 348.55 73.4099C355.49 73.4099 362.64 73.2399 369.57 73.0499L348.74 25.3199ZM354.94 -0.0400391C373.34 43.55 401.53 111.94 411.49 132.03C408.1 131.48 404.9 131.09 401.53 131.09C398.14 131.09 394.95 131.48 391.57 132.03C388.01 119.08 379 98.3999 372.23 81.6899C364.16 81.4999 355.88 81.2999 347.62 81.2999C339.92 81.2999 332.39 81.4999 324.69 81.6899C316.79 101.04 309.66 120.2 306.46 132.03C304.01 131.48 301.76 131.09 299.51 131.09C297.25 131.09 294.98 131.48 292.56 132.03C312.66 88.06 332.21 44.29 351.36 -0.0400391H354.94Z"
          fill={color}
        />
        <path
          d="M524.62 123.78C519.93 127.54 504.35 134.49 485.56 134.49C445.89 134.49 416.98 109.86 416.98 67.2202C416.98 24.5502 445.89 -0.0498047 487.43 -0.0498047C503.59 -0.0498047 518.07 3.89019 528.19 8.57019C526.33 11.9602 525.001 19.3002 524.271 23.2502L523.13 23.6102C517.85 17.7802 505.09 6.90021 487.43 6.90021C457.94 6.90021 435.38 25.4902 435.38 67.2202C435.38 108.92 457.95 127.54 487.43 127.54C505.3 127.54 518.43 117.37 525.56 111.94L526.341 112.69L524.62 123.78Z"
          fill={color}
        />
        <path
          d="M552.82 51.0699C552.82 34.9099 552.08 18.5499 551.32 2.3999C554.51 2.9599 557.52 3.31989 560.52 3.31989C563.52 3.31989 566.56 2.9599 569.72 2.3999C568.98 18.5499 568.23 34.8999 568.23 51.0699C568.23 82.6299 568.59 104.81 568.81 123.21C586.46 123.21 601.86 123.21 620.63 120.79C620.08 122.65 619.71 124.54 619.71 126.4C619.71 128.27 620.08 130.16 620.63 132.04C609.2 131.48 597.53 131.1 586.07 131.1C574.42 131.1 562.96 131.47 551.32 132.04C552.08 115.88 552.82 99.5399 552.82 83.3699V51.0699Z"
          fill={color}
        />
        <path
          d="M646.76 51.0699C646.76 34.9099 646.02 18.5499 645.26 2.3999C648.45 2.9599 651.46 3.31989 654.46 3.31989C657.47 3.31989 660.5 2.9599 663.67 2.3999C662.92 18.5499 662.17 34.8999 662.17 51.0699V83.3699C662.17 99.5299 662.92 115.87 663.67 132.04C660.5 131.48 657.47 131.1 654.46 131.1C651.46 131.1 648.45 131.47 645.26 132.04C646.02 115.88 646.76 99.5399 646.76 83.3699V51.0699Z"
          fill={color}
        />
        <path
          d="M758.75 7.89996C729.45 7.89996 706.88 26.5199 706.88 68.2199C706.88 109.94 729.45 128.54 758.75 128.54C788.25 128.54 810.82 109.94 810.82 68.2199C810.82 26.5099 788.25 7.89996 758.75 7.89996ZM758.75 0.959961C800.26 0.959961 829.22 25.56 829.22 68.21C829.22 110.86 800.27 135.49 758.75 135.49C717.41 135.49 688.48 110.86 688.48 68.21C688.48 25.56 717.41 0.959961 758.75 0.959961Z"
          fill={color}
        />
        <path
          d="M19.4696 192.57V210.98H26.7096C33.5396 210.98 36.6696 207.95 36.6696 201.72C36.6696 198.85 35.9496 196.66 34.4196 195.13C32.7496 193.47 30.3196 192.56 26.7096 192.56H19.4696V192.57ZM28.8896 217.95H19.4696V237.96H10.3096V185.52H25.0896C33.2196 185.52 36.1896 186.28 39.2496 188.01C44.1496 190.75 46.8896 195.45 46.8896 201.2C46.8996 211.51 38.9396 217.95 28.8896 217.95Z"
          fill="#B2A273"
        />
        <path
          d="M95.8703 192.57V209.17H100.6C105.43 209.17 108 208.56 109.7 206.98C111.23 205.54 112.18 203.33 112.18 200.61C112.18 195.3 109.12 192.58 100.93 192.58H95.8703V192.57ZM113.8 237.96L108.98 229.86C105.12 223.41 102.53 219.78 99.4803 216.9C98.4403 215.92 97.6303 215.39 95.8803 215.31V237.96H86.7803V185.52H103.75C116.21 185.52 121.85 192.34 121.85 200.53C121.85 208.65 116.37 214.92 108.01 214.92C110.01 215.91 113.72 220.99 116.62 225.39L124.82 237.96H113.8Z"
          fill="#B2A273"
        />
        <path
          d="M197.66 212.35C197.66 199.55 194.85 191.51 184.88 191.51C175.55 191.51 172.02 198.62 172.02 211.21C172.02 224.71 175.64 232.2 185.13 232.2C193.4 232.2 197.66 226.45 197.66 212.35ZM161.72 211.74C161.72 194.47 171.04 184.76 184.95 184.76C200.62 184.76 208.19 196.51 208.19 211.83C208.19 228.2 199.83 238.79 185.35 238.79C169.51 238.79 161.72 227.28 161.72 211.74Z"
          fill="#B2A273"
        />
        <path
          d="M275.8 196.96C273.23 193.63 270.33 192.43 265.35 192.43H259.48V230.83H265.59C275.4 230.83 279.33 224.7 279.33 212.81C279.34 206.05 278.7 200.68 275.8 196.96ZM283.76 230.61C279.1 236.22 274.27 237.96 263.9 237.96H250.14V185.52H261.72C272.17 185.52 278.36 187.34 283.59 192.95C287.69 197.42 289.62 203.71 289.62 211.9C289.63 219.85 287.56 226.05 283.76 230.61Z"
          fill="#B2A273"
        />
        <path
          d="M351.54 238.87C336.9 238.87 332.32 231.75 332.32 223.34V185.52H341.65V220.78C341.65 228.81 344.71 231.45 351.54 231.45C358.3 231.45 361.59 227.73 361.59 220.78V185.52H370.83V223.27C370.83 232.88 363.77 238.87 351.54 238.87Z"
          fill="#B2A273"
        />
        <path
          d="M436.43 238.79C429.03 238.79 423.16 236.14 418.82 231.22C414.64 226.45 412.48 220.07 412.48 212.49C412.48 206.22 413.68 200.97 416.17 196.59C420.35 189.09 427.99 184.54 436.67 184.54C442.06 184.54 447.21 186.07 450.5 188.64L446.23 194.39C443.26 192.36 440.22 191.45 436.74 191.45C432.01 191.45 428.061 193.71 425.651 197.72C423.721 200.91 422.771 205.3 422.771 211.52C422.771 217.97 423.49 221.6 424.93 224.56C427.34 229.56 431.92 231.99 437.32 231.99C441.26 231.99 444.141 231.01 447.281 228.66L451.62 234.18C447.29 237.28 442.38 238.79 436.43 238.79Z"
          fill="#B2A273"
        />
        <path
          d="M485.89 185.53V192.79H499.96V237.95H509.19V192.79H523.21L524.39 185.53H485.89Z"
          fill="#B2A273"
        />
        <path d="M574.8 185.53H563.65V237.96H574.8V185.53Z" fill="#B2A273" />
        <path
          d="M650.97 212.35C650.97 199.55 648.16 191.51 638.19 191.51C628.86 191.51 625.33 198.62 625.33 211.21C625.33 224.71 628.95 232.2 638.44 232.2C646.7 232.2 650.97 226.45 650.97 212.35ZM615.04 211.74C615.04 194.47 624.36 184.76 638.27 184.76C653.94 184.76 661.51 196.51 661.51 211.83C661.51 228.2 653.15 238.79 638.67 238.79C622.83 238.79 615.04 227.28 615.04 211.74Z"
          fill="#B2A273"
        />
        <path
          d="M731.23 237.96L718.77 213.33C715.79 207.49 712.74 200.9 711.69 197.79L711.44 198.02C711.84 202.42 711.93 207.96 712 212.57L712.32 237.96H703.25V185.52H713.69L727.2 211.14C729.78 215.99 732.19 222.2 732.91 224.62L733.16 224.39C732.91 221.74 732.35 214.25 732.35 209.47L732.19 185.52H740.96V237.96H731.23Z"
          fill="#B2A273"
        />
        <path
          d="M800.39 238.94C794.05 238.94 787.61 237.35 782.13 234.25L785.66 227.57C790.65 230.3 795.08 231.9 800.7 231.9C807.55 231.9 811.64 228.8 811.64 223.55C811.64 219.7 809.07 217.26 803.36 215.75L797.4 214.16C792.34 212.79 788.72 210.83 786.63 207.87C785.1 205.74 784.29 203.24 784.29 200.2C784.29 190.97 792 184.67 803.18 184.67C809.54 184.67 815.88 186.41 820.8 189.67L816.6 195.81C811.47 192.93 807.92 191.8 803.51 191.8C798.03 191.8 794.41 194.6 794.41 198.92C794.41 202.18 796.34 203.84 801.82 205.37L808.41 207.17C816.46 209.38 822.08 214.24 822.08 222.11C822.1 230.84 814.62 238.94 800.39 238.94Z"
          fill="#B2A273"
        />
      </g>
      <defs>
        <clipPath id="clip0_662_3531">
          <rect width="829" height="239" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
