'use client';

import classnames from 'classnames';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { Link } from '@/components/link';
import { Logo } from '@/components/logo';

import { markdownToHtml } from '@/helpers/string';

import { Button } from '../button';

import './index.css';

/* import {
  BrandingDesign,
  ChevronDown,
  FormationConseil,
  RenfortEquipe,
  SiteWebAndApp,
} from '../icons';

const ICON = {
  'branding-design': <BrandingDesign />,
  'formation-conseil': <FormationConseil />,
  'renfort-equipe': <RenfortEquipe />,
  'site-web-app': <SiteWebAndApp />,
};
 */
const BurgerIcon = ({ color = 'black' }) => {
  return (
    <svg
      width="27"
      height="20"
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.9498 16.4745H25.0498C25.9611 16.4745 26.6998 17.2092 26.6998 18.1155C26.6998 19.0218 25.9611 19.7565 25.0498 19.7565H1.9498C1.03853 19.7565 0.299805 19.0218 0.299805 18.1155C0.299805 17.2092 1.03853 16.4745 1.9498 16.4745ZM1.9498 8.26934H25.0498C25.9611 8.26934 26.6998 9.00405 26.6998 9.91036C26.6998 10.8167 25.9611 11.5514 25.0498 11.5514H1.9498C1.03853 11.5514 0.299805 10.8167 0.299805 9.91036C0.299805 9.00405 1.03853 8.26934 1.9498 8.26934ZM1.9498 0.064209H25.0498C25.9611 0.064209 26.6998 0.798921 26.6998 1.70523C26.6998 2.61155 25.9611 3.34626 25.0498 3.34626H1.9498C1.03853 3.34626 0.299805 2.61155 0.299805 1.70523C0.299805 0.798921 1.03853 0.064209 1.9498 0.064209Z"
        fill={color}
      />
    </svg>
  );
};

export const MobileMenu = ({ blok, page }) => {
  const lastScrollTop = useRef(0);
  const [open, setOpen] = useState(false);
  const [menuItemsOpen, setMenuItemsOpen] = useState({});

  const toggle = useCallback(() => {
    lastScrollTop.current = typeof window !== 'undefined' ? window.scrollY : 0;
    setOpen((state) => !state);
  }, []);

  useLayoutEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [open]);

  const handleMenuItemsOpen = useCallback((menuId) => {
    setMenuItemsOpen((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId],
    }));
  }, []);

  return (
    <>
      <header
        className={classnames('mobile-menu', { 'mobile-menu--open': open })}
      >
        <button className="mobile-menu__button" onClick={toggle}>
          <div className="mobile-menu__button__burger">
            <BurgerIcon color="var(--color)" />
          </div>
        </button>

        <menu className="mobile-menu__links">
          <div className="mobile-menu__links__header">
            <div className="mobile-menu__links__header__wrap">
              <Link href="/" className="mobile-menu__logo">
                <Logo color="var(--black)" />
              </Link>
            </div>

            <button
              className={classnames(
                'mobile-menu__button',
                'mobile-menu__button--close',
              )}
              onClick={toggle}
            >
              <img
                src="/static/icons/cross-black.svg"
                width="50"
                height="50"
                alt=""
                className="mobile-menu__button__cross"
              />
            </button>
          </div>

          <div className="mobile-menu__top">
            <h2 className="mobile-menu__title">Menu</h2>
            <ul className="mobile-menu__list">
              {[...(blok.content.links || []), ...(blok.content.cta || [])].map(
                (item, index) => (
                  <React.Fragment key={item._uid}>
                    {item.link && (
                      <li
                        className={classnames('mobile-menu__item', {
                          'mobile-menu__link--open': menuItemsOpen[item._uid],
                        })}
                        onClick={() => handleMenuItemsOpen(item._uid)}
                      >
                        <Link
                          link={item.link}
                          className={classnames('mobile-menu__link', {
                            'mobile-menu__link--current':
                              item.link.cached_url === page?.full_slug,
                            'mobile-menu__link--has-sub-links':
                              item.subLinks && item.subLinks.length > 0,
                          })}
                        >
                          <div className="mobile-menu__link__title">
                            {item.title}
                            {item.subLinks && item.subLinks.length > 0 && (
                              <span>{item.subLinks.length}</span>
                            )}
                          </div>
                        </Link>

                        {item.subLinks && item.subLinks.length > 0 && (
                          <ul className="mobile-menu__sub-links">
                            {item.subLinks.map((subLink) => (
                              <li
                                key={subLink._uid}
                                className="mobile-menu__sub-link"
                              >
                                <Link
                                  link={subLink.link}
                                  className="mobile-menu__sub-link__link"
                                >
                                  <div className="mobile-menu__sub-link__title">
                                    {subLink.title}
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    )}
                  </React.Fragment>
                ),
              )}
            </ul>
          </div>

          <div />
        </menu>
      </header>
    </>
  );
};
