'use client';

import classnames from 'classnames';
import React from 'react';

import { Link } from '@/components/link';

import { detectLocale } from '@/helpers/storyblok';

import './index.css';

export const LanguageSwitcher = ({ locale, story }) => {
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  if (!story || !story.alternates) {
    return null;
  }

  return (
    <div
      className={classnames('language-switcher', {
        'language-switcher--open': open,
      })}
      onMouseEnter={toggleOpen}
      onMouseLeave={toggleOpen}
    >
      <div className="language-switcher__current">
        <p
          dangerouslySetInnerHTML={{ __html: locale.shortCode.toUpperCase() }}
        />
        <img
          src="/static/icons/chevron-down.svg"
          alt=""
          className="language-switcher__icon"
        />
      </div>

      {open ? (
        <div className="language-switcher__dropdown">
          {story.alternates?.map((alternate, index) => {
            const alternateLocale = detectLocale(
              (alternate?.full_slug || '/').split('/'),
            );

            return (
              <Link page={alternate} key={alternateLocale.shortCode}>
                {alternateLocale.shortCode.toUpperCase()}
              </Link>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
