import React from 'react';

export const ArrowRight = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.749683 7.75001C0.550772 7.75001 0.360004 7.67099 0.219353 7.53034C0.078701 7.38969 -0.00031662 7.19892 -0.00031662 7.00001C-0.00031662 6.8011 0.078701 6.61033 0.219353 6.46968C0.360004 6.32903 0.550772 6.25001 0.749683 6.25001H9.99968V1.00001C9.99981 0.851772 10.0439 0.706895 10.1263 0.58368C10.2087 0.460464 10.3258 0.364436 10.4628 0.307725C10.5997 0.251014 10.7504 0.236162 10.8958 0.265047C11.0412 0.293931 11.1748 0.365255 11.2797 0.470012L17.2797 6.47001C17.4201 6.61064 17.499 6.80126 17.499 7.00001C17.499 7.19876 17.4201 7.38939 17.2797 7.53001L11.2797 13.53C11.1748 13.6348 11.0412 13.7061 10.8958 13.735C10.7504 13.7639 10.5997 13.749 10.4628 13.6923C10.3258 13.6356 10.2087 13.5396 10.1263 13.4163C10.0439 13.2931 9.99981 13.1483 9.99968 13V7.75001H0.749683Z"
        fill="var(--color)"
      />
    </svg>
  );
};

export const ChevronDown = () => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L5 5L9 1"
        stroke="var(--color)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ChevronUp = () => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5L5 1L1 5"
        stroke="var(--color)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ChevronRight = () => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="7.55112"
        height="1.03374"
        rx="0.516869"
        transform="matrix(0.767651 0.640868 -0.589302 0.807913 3.42249 0.614746)"
        fill="var(--color)"
      />
      <rect
        width="7.4546"
        height="1.03357"
        rx="0.516784"
        transform="matrix(0.78336 -0.621569 -0.568941 -0.822379 3.3573 10.3384)"
        fill="var(--color)"
      />
    </svg>
  );
};

export const Conception = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2069 3.60443L19.2069 3.60444L19.209 3.60236C19.3085 3.50201 19.427 3.42235 19.5574 3.36799C19.6879 3.31363 19.8279 3.28564 19.9692 3.28564C20.1106 3.28564 20.2505 3.31363 20.381 3.36799C20.5115 3.42235 20.6299 3.50201 20.7294 3.60237L20.7309 3.60382L22.3958 5.26879L22.3973 5.27023C22.4977 5.36977 22.5773 5.4882 22.6317 5.61867L23.0932 5.42639L22.6317 5.61868C22.686 5.74915 22.714 5.8891 22.714 6.03045C22.714 6.1718 22.686 6.31175 22.6317 6.44223L23.0932 6.63452L22.6317 6.44223C22.5773 6.57271 22.4977 6.69113 22.3973 6.79067L22.3952 6.79273L13.5373 15.6817L9.67664 16.3766L10.3209 12.4594L19.2069 3.60443Z"
        stroke="var(--color)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-3-inside-1_372_509" fill="var(--color)">
        <path d="M20.8485 16.9312V21.6434C20.8485 22.06 20.683 22.4595 20.3885 22.7541C20.0939 23.0486 19.6944 23.2141 19.2778 23.2141H4.35588C3.9393 23.2141 3.53978 23.0486 3.24521 22.7541C2.95064 22.4595 2.78516 22.06 2.78516 21.6434V6.72148C2.78516 6.3049 2.95064 5.90538 3.24521 5.61081C3.53978 5.31624 3.9393 5.15076 4.35588 5.15076H9.06807" />
      </mask>
      <path
        d="M21.8485 16.9312C21.8485 16.3789 21.4008 15.9312 20.8485 15.9312C20.2962 15.9312 19.8485 16.3789 19.8485 16.9312H21.8485ZM2.78516 21.6434H1.78516H2.78516ZM4.35588 5.15076V4.15076V5.15076ZM9.06807 6.15076C9.62035 6.15076 10.0681 5.70304 10.0681 5.15076C10.0681 4.59847 9.62035 4.15076 9.06807 4.15076V6.15076ZM19.8485 16.9312V21.6434H21.8485V16.9312H19.8485ZM19.8485 21.6434C19.8485 21.7948 19.7884 21.9399 19.6814 22.047L21.0956 23.4612C21.5777 22.9791 21.8485 22.3252 21.8485 21.6434H19.8485ZM19.6814 22.047C19.5743 22.154 19.4292 22.2141 19.2778 22.2141V24.2141C19.9596 24.2141 20.6135 23.9433 21.0956 23.4612L19.6814 22.047ZM19.2778 22.2141H4.35588V24.2141H19.2778V22.2141ZM4.35588 22.2141C4.20452 22.2141 4.05935 22.154 3.95232 22.047L2.53811 23.4612C3.02021 23.9433 3.67408 24.2141 4.35588 24.2141V22.2141ZM3.95232 22.047C3.84529 21.9399 3.78516 21.7948 3.78516 21.6434H1.78516C1.78516 22.3252 2.056 22.9791 2.53811 23.4612L3.95232 22.047ZM3.78516 21.6434V6.72148H1.78516V21.6434H3.78516ZM3.78516 6.72148C3.78516 6.57012 3.84529 6.42495 3.95232 6.31792L2.53811 4.90371C2.056 5.38581 1.78516 6.03969 1.78516 6.72148H3.78516ZM3.95232 6.31792C4.05935 6.21089 4.20452 6.15076 4.35588 6.15076V4.15076C3.67408 4.15076 3.02021 4.4216 2.53811 4.90371L3.95232 6.31792ZM4.35588 6.15076H9.06807V4.15076H4.35588V6.15076Z"
        fill="var(--color)"
        mask="url(#path-3-inside-1_372_509)"
      />
    </svg>
  );
};

export const Production = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_372_488)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.13773 7.76856C15.6979 7.93813 19.1751 3.45657 20.966 3.49197C21.8562 3.50972 22.2207 4.1857 22.222 5.97182L22.2323 18.1528C22.2338 19.9384 21.8703 20.5999 20.9796 20.5822C19.2306 20.5476 15.8737 16.1478 7.73909 15.7773L7.74426 21.8568C7.74455 22.6124 6.55441 22.5888 6.55407 21.8324L6.54902 15.7459L5.68884 15.7289C0.302503 15.6224 0.294792 7.63292 5.68285 7.73985L7.13773 7.76856ZM6.54803 14.5974L6.54297 8.90539C5.40493 8.88287 4.45677 8.80074 3.64102 9.59654C2.21596 10.9864 2.56521 14.5188 5.68859 14.5806L6.54803 14.5974ZM7.73329 8.92164L7.73823 14.6283C15.8922 14.9864 20.0345 19.4149 20.9797 19.4337C21.0249 19.4346 21.0432 19.079 21.0424 18.1282L21.0321 5.94755C21.0314 4.99761 21.0123 4.64144 20.9662 4.64055C20.0209 4.62141 15.8859 8.88613 7.73329 8.92164Z"
          fill="var(--color)"
        />
      </g>
      <defs>
        <clipPath id="clip0_372_488">
          <rect width="26" height="26" fill="var(--color)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Realisation = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4486 10.4687L11.2086 6.27821C11.1303 6.22557 11.0392 6.19534 10.9452 6.19075C10.8511 6.18616 10.7576 6.20738 10.6746 6.25215C10.5916 6.29692 10.5222 6.36356 10.4738 6.44495C10.4254 6.52634 10.3999 6.61943 10.4 6.71429V15.0952C10.3999 15.1901 10.4253 15.2832 10.4737 15.3646C10.522 15.446 10.5913 15.5127 10.6743 15.5575C10.7574 15.6023 10.8509 15.6235 10.945 15.6189C11.0392 15.6143 11.1303 15.584 11.2086 15.5313L17.4486 11.3408C17.5199 11.293 17.5784 11.2282 17.6189 11.1521C17.6593 11.076 17.6805 10.9911 17.6805 10.9048C17.6805 10.8185 17.6593 10.7335 17.6189 10.6574C17.5784 10.5813 17.5199 10.5165 17.4486 10.4687ZM11.44 14.117V7.6925L16.2227 10.9048L11.44 14.117ZM24.44 2H1.56C1.14626 2 0.74947 2.16556 0.456913 2.46026C0.164357 2.75496 0 3.15466 0 3.57143V18.2381C0 18.6549 0.164357 19.0546 0.456913 19.3493C0.74947 19.644 1.14626 19.8095 1.56 19.8095H24.44C24.8537 19.8095 25.2505 19.644 25.5431 19.3493C25.8356 19.0546 26 18.6549 26 18.2381V3.57143C26 3.15466 25.8356 2.75496 25.5431 2.46026C25.2505 2.16556 24.8537 2 24.44 2ZM24.96 18.2381C24.96 18.377 24.9052 18.5103 24.8077 18.6085C24.7102 18.7067 24.5779 18.7619 24.44 18.7619H1.56C1.42209 18.7619 1.28982 18.7067 1.1923 18.6085C1.09479 18.5103 1.04 18.377 1.04 18.2381V3.57143C1.04 3.43251 1.09479 3.29927 1.1923 3.20104C1.28982 3.10281 1.42209 3.04762 1.56 3.04762H24.44C24.5779 3.04762 24.7102 3.10281 24.8077 3.20104C24.9052 3.29927 24.96 3.43251 24.96 3.57143V18.2381ZM26 23.4762C26 23.6151 25.9452 23.7483 25.8477 23.8466C25.7502 23.9448 25.6179 24 25.48 24H0.52C0.382087 24 0.249823 23.9448 0.152305 23.8466C0.0547857 23.7483 0 23.6151 0 23.4762C0 23.3373 0.0547857 23.204 0.152305 23.1058C0.249823 23.0076 0.382087 22.9524 0.52 22.9524H25.48C25.6179 22.9524 25.7502 23.0076 25.8477 23.1058C25.9452 23.204 26 23.3373 26 23.4762Z"
        fill="var(--color)"
      />
    </svg>
  );
};

export const Exploitation = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_372_808)">
        <path
          d="M18.8956 7.10711C18.4499 6.66129 18.189 6.06367 18.1649 5.43376C18.1409 4.80384 18.3555 4.18807 18.7659 3.70959C18.7999 3.66979 18.8175 3.61864 18.8153 3.56638C18.8131 3.51412 18.7913 3.46462 18.7541 3.4278L16.39 1.061C16.3509 1.02194 16.2979 1 16.2427 1C16.1874 1 16.1345 1.02194 16.0954 1.061L12.3271 4.82924C12.1881 4.96824 12.0834 5.13772 12.0212 5.32424C11.9593 5.5112 11.8547 5.68116 11.7157 5.82064C11.5767 5.96013 11.4071 6.0653 11.2203 6.12782C11.0337 6.19001 10.8641 6.29473 10.7248 6.43371L1.061 16.0954C1.02194 16.1345 1 16.1874 1 16.2427C1 16.2979 1.02194 16.3509 1.061 16.39L3.42512 18.7541C3.46194 18.7913 3.51145 18.8131 3.5637 18.8153C3.61596 18.8175 3.66712 18.7999 3.70691 18.7659C4.18529 18.3551 4.8012 18.1401 5.43133 18.164C6.06146 18.1879 6.65932 18.4489 7.10521 18.8948C7.5511 19.3407 7.81212 19.9385 7.83601 20.5687C7.85991 21.1988 7.64491 21.8147 7.23407 22.2931C7.20013 22.3329 7.18248 22.384 7.18467 22.4363C7.18685 22.4886 7.20871 22.5381 7.24586 22.5749L9.60998 24.939C9.64906 24.9781 9.70205 25 9.7573 25C9.81256 25 9.86555 24.9781 9.90463 24.939L19.569 15.2752C19.7079 15.1359 19.8127 14.9663 19.8749 14.7797C19.9368 14.5927 20.0414 14.4227 20.1804 14.2833C20.3194 14.1438 20.489 14.0386 20.6758 13.9761C20.8623 13.914 21.0318 13.8092 21.1708 13.6702L24.939 9.90195C24.9781 9.86287 25 9.80988 25 9.75462C25 9.69937 24.9781 9.64638 24.939 9.6073L22.5749 7.24318C22.5381 7.20603 22.4886 7.18418 22.4363 7.18199C22.384 7.1798 22.3329 7.19745 22.2931 7.2314C21.8153 7.64249 21.1999 7.85807 20.57 7.83503C19.9401 7.81198 19.3421 7.55202 18.8956 7.10711Z"
          stroke="var(--color)"
          strokeMiterlimit="10"
        />
        <path
          d="M12.7274 7.27284L11.9092 6.45459M14.9091 9.45452L14.3634 8.90935M17.0908 11.6367L16.5456 11.091M19.5455 14.091L18.7273 13.2727"
          stroke="var(--color)"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_372_808">
          <rect width="26" height="26" fill="var(--color)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Mail = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2775 4.58383L7.2775 0.583831C7.19534 0.529011 7.09877 0.499756 7 0.499756C6.90123 0.499756 6.80466 0.529011 6.7225 0.583831L0.7225 4.58383C0.654007 4.62953 0.597859 4.69143 0.559044 4.76405C0.520228 4.83666 0.499947 4.91774 0.5 5.00008V11.5001C0.5 11.7653 0.605357 12.0197 0.792893 12.2072C0.98043 12.3947 1.23478 12.5001 1.5 12.5001H12.5C12.7652 12.5001 13.0196 12.3947 13.2071 12.2072C13.3946 12.0197 13.5 11.7653 13.5 11.5001V5.00008C13.5001 4.91774 13.4798 4.83666 13.441 4.76405C13.4021 4.69143 13.346 4.62953 13.2775 4.58383ZM7 1.60071L12.1194 5.01383L7.93188 8.00008H6.06938L1.88188 5.01383L7 1.60071ZM1.5 11.5001V5.97071L5.61875 8.90821C5.70355 8.96878 5.80516 9.00133 5.90938 9.00133H8.09062C8.19484 9.00133 8.29645 8.96878 8.38125 8.90821L12.5 5.97071V11.5001H1.5Z"
        fill="#B5A16F"
      />
    </svg>
  );
};

export const Phone = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8981 8.90387L8.95376 7.58449L8.94563 7.58074C8.79278 7.51536 8.62603 7.48913 8.46049 7.50441C8.29494 7.51968 8.13581 7.57599 7.99751 7.66824C7.98122 7.679 7.96557 7.69068 7.95063 7.70324L6.42938 9.00012C5.46563 8.53199 4.47063 7.54449 4.00251 6.59324L5.30126 5.04887C5.31376 5.03324 5.32563 5.01762 5.33688 5.00074C5.42715 4.86281 5.48192 4.70469 5.49631 4.54048C5.5107 4.37626 5.48428 4.21103 5.41938 4.05949V4.05199L4.09626 1.10262C4.01047 0.904658 3.86296 0.739752 3.67575 0.632517C3.48854 0.525281 3.27166 0.481468 3.05751 0.507618C2.21061 0.619059 1.43324 1.03497 0.870589 1.67768C0.307935 2.32038 -0.00152558 3.14592 5.6554e-06 4.00012C5.6554e-06 8.96262 4.03751 13.0001 9.00001 13.0001C9.8542 13.0016 10.6797 12.6922 11.3224 12.1295C11.9652 11.5669 12.3811 10.7895 12.4925 9.94262C12.5187 9.72853 12.475 9.51171 12.3679 9.32451C12.2607 9.13731 12.096 8.98976 11.8981 8.90387ZM9.00001 12.0001C6.87898 11.9978 4.8455 11.1542 3.34571 9.65441C1.84592 8.15462 1.00232 6.12114 1.00001 4.00012C0.997654 3.3898 1.21754 2.79949 1.61859 2.33943C2.01964 1.87938 2.57444 1.58103 3.17938 1.50012C3.17913 1.50261 3.17913 1.50512 3.17938 1.50762L4.49188 4.44512L3.20001 5.99137C3.18689 6.00646 3.17498 6.02255 3.16438 6.03949C3.07033 6.18382 3.01515 6.34999 3.0042 6.52191C2.99325 6.69382 3.0269 6.86565 3.10188 7.02074C3.66813 8.17887 4.83501 9.33699 6.00563 9.90262C6.16186 9.9769 6.33468 10.0094 6.50722 9.99689C6.67976 9.9844 6.8461 9.92737 6.99001 9.83137C7.00605 9.82056 7.02149 9.80887 7.03626 9.79637L8.55563 8.50012L11.4931 9.81574C11.4931 9.81574 11.4981 9.81574 11.5 9.81574C11.4201 10.4215 11.1222 10.9775 10.662 11.3795C10.2019 11.7815 9.61104 12.0022 9.00001 12.0001Z"
        fill="#B5A16F"
      />
    </svg>
  );
};

export const Location = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 13H7.40875C7.92803 12.5363 8.41774 12.0406 8.875 11.5156C10.5906 9.5425 11.5 7.4625 11.5 5.5C11.5 4.04131 10.9205 2.64236 9.88909 1.61091C8.85764 0.579463 7.45869 0 6 0C4.54131 0 3.14236 0.579463 2.11091 1.61091C1.07946 2.64236 0.5 4.04131 0.5 5.5C0.5 7.4625 1.40688 9.5425 3.125 11.5156C3.58226 12.0406 4.07197 12.5363 4.59125 13H1.5C1.36739 13 1.24021 13.0527 1.14645 13.1464C1.05268 13.2402 1 13.3674 1 13.5C1 13.6326 1.05268 13.7598 1.14645 13.8536C1.24021 13.9473 1.36739 14 1.5 14H10.5C10.6326 14 10.7598 13.9473 10.8536 13.8536C10.9473 13.7598 11 13.6326 11 13.5C11 13.3674 10.9473 13.2402 10.8536 13.1464C10.7598 13.0527 10.6326 13 10.5 13ZM1.5 5.5C1.5 4.30653 1.97411 3.16193 2.81802 2.31802C3.66193 1.47411 4.80653 1 6 1C7.19347 1 8.33807 1.47411 9.18198 2.31802C10.0259 3.16193 10.5 4.30653 10.5 5.5C10.5 9.07687 7.03313 12.0625 6 12.875C4.96687 12.0625 1.5 9.07687 1.5 5.5ZM8.5 5.5C8.5 5.00555 8.35338 4.5222 8.07867 4.11107C7.80397 3.69995 7.41352 3.37952 6.95671 3.1903C6.49989 3.00108 5.99723 2.95157 5.51227 3.04804C5.02732 3.1445 4.58186 3.3826 4.23223 3.73223C3.8826 4.08186 3.6445 4.52732 3.54804 5.01227C3.45157 5.49723 3.50108 5.99989 3.6903 6.45671C3.87952 6.91352 4.19995 7.30397 4.61107 7.57867C5.0222 7.85338 5.50555 8 6 8C6.66304 8 7.29893 7.73661 7.76777 7.26777C8.23661 6.79893 8.5 6.16304 8.5 5.5ZM4.5 5.5C4.5 5.20333 4.58797 4.91332 4.7528 4.66664C4.91762 4.41997 5.15189 4.22771 5.42597 4.11418C5.70006 4.00065 6.00166 3.97094 6.29264 4.02882C6.58361 4.0867 6.85088 4.22956 7.06066 4.43934C7.27044 4.64912 7.4133 4.91639 7.47118 5.20736C7.52906 5.49834 7.49935 5.79994 7.38582 6.07403C7.27229 6.34811 7.08003 6.58238 6.83335 6.7472C6.58668 6.91203 6.29667 7 6 7C5.60218 7 5.22064 6.84196 4.93934 6.56066C4.65804 6.27936 4.5 5.89782 4.5 5.5Z"
        fill="#B5A16F"
      />
    </svg>
  );
};

export const Ellipse = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="5" r="4" stroke="black" strokeWidth="2" />
    </svg>
  );
};
