'use client';

import classnames from 'classnames';
import React from 'react';
import Spinner from 'react-svg-spinner';

import * as Icons from '@/components/icons';
import { Link } from '@/components/link';

import { isLink } from '@/helpers/storyblok/is-link';

import './index.css';

delete Spinner.defaultProps;

const ICON_MAPPING = {
  'arrow-right': <Icons.ArrowRight />,
  'chevron-down': <Icons.ChevronDown />,
  'chevron-up': <Icons.ChevronUp />,
  'chevron-right': <Icons.ChevronRight />,
};

/**
 * @param {object} props - Props for the button.
 * @param {'primary'|'secondary'|'transparent'|'black'} props.theme - The theme of the button.
 * @param {'xsmall'|'small'|'normal'|'large'} props.height - The height of the button.
 */
export const Button = ({
  as = 'button',
  link = null,
  theme = 'primary',
  height = 'normal',
  loading = false,
  leftIcon = null,
  rightIcon = null,
  fullWidth = false,
  width = 'normal',
  children,
  dangerouslySetInnerHTML,
  disabled = false,
  onClick,
  ...rest
}) => {
  const El = isLink(link) ? Link : as;

  return (
    <div
      className={classnames(
        'button',
        `button--theme-${theme}`,
        `button--height-${height}`,
        `button--width-${width}`,
        {
          'button--has-left-icon': leftIcon,
          'button--has-right-icon': rightIcon,
          'button--disabled': disabled,
          'button--loading': loading,
          'button--full-width': fullWidth,
        },
      )}
    >
      <div className="button__background"></div>

      <div className="button__content">
        {/*         {ICON_MAPPING[leftIcon] ? (
          <div className="button__icon button__icon--left">
            {ICON_MAPPING[leftIcon]}
          </div>
        ) : null} */}

        <El
          className="button__text"
          link={El === Link ? link : null}
          disabled={El === 'button' ? disabled : undefined}
          onClick={onClick}
          {...rest}
          {...(children ? { children } : { dangerouslySetInnerHTML })}
        />

        {ICON_MAPPING[rightIcon] ? (
          <div className="button__icon button__icon--right">
            {ICON_MAPPING[rightIcon]}
          </div>
        ) : null}

        <div className="button__spinner">
          <Spinner
            color="var(--color)"
            speed="fast"
            size="20"
            gap={4}
            thickness={3}
          />
        </div>
      </div>
    </div>
  );
};
