'use client';

// @ts-nocheck
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import React, { forwardRef } from 'react';

import {
  DEFAULT_FOCUS_POINT,
  FOCUS_POINT,
  generateImageProps,
  isImage,
} from '@/helpers/image';

/**
 * @typedef {Object} ImageComponentProps
 * @property {Object} props.image
 * @property {string} props.sizes
 * @property {boolean} [props.lazy=false]
 * @property {string} [props.draggable='true']
 * @property {string} [props.focusPoint=null]
 *
 * @property {string} [props.className=undefined]
 * @property {any} [props.onClick=undefined]
 * @property {string} [props.fetchPriority=undefined]
 */

/**
 * @param {ImageComponentProps} props
 */
export const Image = forwardRef(
  /**
   * @param {ImageComponentProps} props
   */
  function ImageWithRef(
    {
      image,
      sizes = '100vw',
      lazy = false,
      draggable = 'true',
      focusPoint = null,
      className,
      ...rest
    },
    ref,
  ) {
    if (isImage(image)) {
      const objectPosition = FOCUS_POINT[focusPoint] || DEFAULT_FOCUS_POINT;

      return (
        <img
          ref={ref}
          {...generateImageProps(image, {
            sizes,
            lazy,
            draggable,
          })}
          className={className}
          style={{
            objectPosition,
          }}
          {...rest}
        />
      );
    }

    return null;
  },
);
