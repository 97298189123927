export const CONFIG = {
  title: 'Amaclio Productions',
  titleSuffix: ' - Amaclio Productions',

  description: '',
};

/**
 * @typedef {Object} Locale
 * @property {string} title
 * @property {string} shortName
 * @property {string} locale
 * @property {string} shortCode
 * @property {string} pathPrefix
 */

/**
 * @type {Object<string, Locale>}
 */
export const LOCALE = {
  FR: {
    title: 'Français',
    shortName: 'FR',
    locale: 'fr-fr',
    shortCode: 'fr',
    pathPrefix: '/',
  },
  EN: {
    title: 'English',
    shortName: 'EN',
    locale: 'en-us',
    shortCode: 'en',
    pathPrefix: '/en',
  },
};

/**
 * @type {Locale[]}
 */
export const LOCALES = [LOCALE.FR, LOCALE.EN];

export const DEFAULT_LOCALE = LOCALES[0];
